<template>
  <CCard class="px-2 pt-1">
    <CRow>
      <CCol col="2" class="">
        <img
          :src="userImage"
          :alt="defaultImg"
          height="100px"
          width="100px"
          class="rounded "
        />
      </CCol>

      <CCol md="5" class="ml-2">
        <CRow v-for="item in userInfo" class=" py-1" :key="item.key">
          <CCol col="4">
            <span class="label"
              ><strong>{{ item.label }}</strong>
            </span>
          </CCol>
          <CCol>
            <span class="table-overflow-elipses">{{ item.value }} </span>
          </CCol>
        </CRow>
        <CRow class=" py-1">
          <CCol col="4">
            <span class="label"><strong>Email: </strong> </span>
          </CCol>
          <CCol>
            <div class="table-overflow-elipses" v-html="getEmail(user)"></div>
          </CCol>
        </CRow>
      </CCol>
      <CCol class="ml-2">
        <CRow>
          <CCol col="4">
            <span class="label"><strong>Status: </strong> </span>
          </CCol>
          <CCol>
            <CBadge :color="getBadgeColor(user)">
              {{ user.status }}
            </CBadge>
          </CCol>
        </CRow>
        <CRow class=" py-1">
          <CCol col="4">
            <span class="label"><strong>Last Active: </strong> </span>
          </CCol>
          <CCol>
            <div class="table-overflow-elipses">
              {{ getDateTime(user.lastActive) }}
            </div>
          </CCol>
        </CRow>
        <CRow class="py-1">
          <CCol col="4">
            <span class="label"><strong>Branch: </strong> </span>
          </CCol>
          <CCol>
            <div class="table-overflow-elipses">
              {{ user.branch_id }}
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </CCard>
</template>


<script>
const defaultIMG =
"https://s.gravatar.com/avatar/871749fa663b40fb1b7813e6616e69ff?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fth.png"

export default {
  name: "UserDetailsCard",
  props: {
    user: Object, 
    defaultImg: {
      type: String, 
      default: defaultIMG,
    }
  },
  data() {
    return {

      
    };
  },
  computed: {
     userInfo() {
      // Returns a map of user summary info
      return [
        { key: "uid", label: "User ID:", value: this.user.uid },
        { key: "role", label: "User Role:", value: this.role },
       
        {
          key: "registered",
          label: "Registered: ",
          value: this.getDateTime(this.user.registered)
        }
      ];
    },
    userImage() {
      if (this.user) 
      {
        if(this.user.img)
          return this.user.img;
        else
          return this.user.picture;
      }
       else
        return defaultIMG;
    },
    userEmail() {
      return this.userData.filter(param => param.key === "email")[0].value;
    },
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" }
      ];
    },
    role() {
      let item = this.user;
      return this.$app.getUserRole(item.role); 
      
    },
    userData() {
      const id = this.$route.params.id;

      this._selectedUser = usersData.find((user, index) => index + 1 == id);

      this.form.user = { ...this._selectedUser }; // Data backing object
      const userDetails = this.user
        ? Object.entries(this.user)
        : [["id", "Not found"]];
      return userDetails.map(([key, value]) => {
        return { key, value };
      });
    },
    visibleData() {
      return this.userData.filter(param => param.key !== "username");
    },
    username() {
      return this.user ? this.user.first : ""; // this.userData.filter(param => param.key === "username")[0].value;
    }
  },
  methods: {
    getDateTime(date) {
      return this.$moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    getEmail(item) {
      if (!item || !item.email) return "";

      return `<a href="mailto:${item.email}" target="_blank">${item.email} </a>`;
    },
    getBadgeColor(item) {
      if (!item || !item.status) {
        return "";
      }
      switch (item.status.toLowerCase()) {
        case "active":
          return "success";
          break;
        case "pending":
        case "inactive":
          return "warning";
          break;
        default:
          return "danger";
      }
    }
  }
};
</script>