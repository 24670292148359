var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticClass: "px-2 pt-1" },
    [
      _c(
        "CRow",
        [
          _c("CCol", { attrs: { col: "2" } }, [
            _c("img", {
              staticClass: "rounded ",
              attrs: {
                src: _vm.userImage,
                alt: _vm.defaultImg,
                height: "100px",
                width: "100px"
              }
            })
          ]),
          _c(
            "CCol",
            { staticClass: "ml-2", attrs: { md: "5" } },
            [
              _vm._l(_vm.userInfo, function(item) {
                return _c(
                  "CRow",
                  { key: item.key, staticClass: " py-1" },
                  [
                    _c("CCol", { attrs: { col: "4" } }, [
                      _c("span", { staticClass: "label" }, [
                        _c("strong", [_vm._v(_vm._s(item.label))])
                      ])
                    ]),
                    _c("CCol", [
                      _c("span", { staticClass: "table-overflow-elipses" }, [
                        _vm._v(_vm._s(item.value) + " ")
                      ])
                    ])
                  ],
                  1
                )
              }),
              _c(
                "CRow",
                { staticClass: " py-1" },
                [
                  _c("CCol", { attrs: { col: "4" } }, [
                    _c("span", { staticClass: "label" }, [
                      _c("strong", [_vm._v("Email: ")])
                    ])
                  ]),
                  _c("CCol", [
                    _c("div", {
                      staticClass: "table-overflow-elipses",
                      domProps: { innerHTML: _vm._s(_vm.getEmail(_vm.user)) }
                    })
                  ])
                ],
                1
              )
            ],
            2
          ),
          _c(
            "CCol",
            { staticClass: "ml-2" },
            [
              _c(
                "CRow",
                [
                  _c("CCol", { attrs: { col: "4" } }, [
                    _c("span", { staticClass: "label" }, [
                      _c("strong", [_vm._v("Status: ")])
                    ])
                  ]),
                  _c(
                    "CCol",
                    [
                      _c(
                        "CBadge",
                        { attrs: { color: _vm.getBadgeColor(_vm.user) } },
                        [_vm._v(" " + _vm._s(_vm.user.status) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CRow",
                { staticClass: " py-1" },
                [
                  _c("CCol", { attrs: { col: "4" } }, [
                    _c("span", { staticClass: "label" }, [
                      _c("strong", [_vm._v("Last Active: ")])
                    ])
                  ]),
                  _c("CCol", [
                    _c("div", { staticClass: "table-overflow-elipses" }, [
                      _vm._v(
                        " " + _vm._s(_vm.getDateTime(_vm.user.lastActive)) + " "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "CRow",
                { staticClass: "py-1" },
                [
                  _c("CCol", { attrs: { col: "4" } }, [
                    _c("span", { staticClass: "label" }, [
                      _c("strong", [_vm._v("Branch: ")])
                    ])
                  ]),
                  _c("CCol", [
                    _c("div", { staticClass: "table-overflow-elipses" }, [
                      _vm._v(" " + _vm._s(_vm.user.branch_id) + " ")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }